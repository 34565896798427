import React from 'react';
import './Name.css';


function Name() {
  

  return (
    <div className="name">
      <p>Joann Carter</p>
    </div>
  );
}

export default Name;